import React, {
    PureComponent
} from 'react';

import { withRouter } from 'react-router-dom';
import { HawbDataElements } from '../../dataComponentsXdock/HawbDataElements';
import RTable from '../../componentsGeneral/Rsuite/Table/RTable';
import { Row, Col, Panel, Loader, Button } from 'rsuite';
import RButtonPrimary from '../../componentsGeneral/Rsuite/Button/RButtonPrimary'
import RSearchInput from '../../componentsGeneral/Rsuite/SearchInput/RSearchInput';
import RSelectPicker from '../../componentsGeneral/Rsuite/InputPicker/RSelectPicker';
import queryString from 'query-string';
import { queryStringParse, formatDateForUrlSearch, formatNumberForAPI, formatStringForAPI, isNumeric, formatJsonStringForMsSql, replaceNegativeWithZero, formatDateForAPI } from '../../../resources/logic/functions';
import RButtonHideShowFilter from '../../componentsGeneral/Rsuite/Button/RButtonHideShowFilter'
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import { hawbStatus } from '../../../app/appConfig';
import RDatePickerRange from '../../componentsGeneral/Rsuite/DatePicker/RDatePickerRange';
import BuildLoadDrawer from './BuildLoadDrawer';
import RSearchInputNumberAsString from '../../componentsGeneral/Rsuite/SearchInput/RSearchInputNumberAsString';



//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


const tableIdCol = "HouseID"


const mountRef = React.createRef();

class Warehouse extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            // Table Check Box
            checkedKeys: [],

            //Search Values
            searchHouseID: '',
            searchHouseRef: '',
            searchMasterID: '',
            searchMaster: '',
            searchOrderNo: '',
            searchShipmentETDStart: null,
            searchShipmentETDEnd: null,
            searchOrigin: null,
            searchLastDischargePort: null,
            searchETADischargeStart: null,
            searchETADischargeEnd: null,
            searchEstCartageDeliveryStart: null,
            searchEstCartageDeliveryEnd: null,
            searchActualCartageDeliveryStart: null,
            searchActualCartageDeliveryEnd: null,
            searchHawbStatusID: 1,
            searchConsignee: null,
            searchDestination: null,

            //Filter Shown
            isFilterShown: true,



            //Build Load Drawer
            BuildLoadDict: {},
            NewLoadData: {},
            unsavedBuildLoadChanges: false,
            showBuildLoadDrawer: false,
            existingDataChangeLoading: false

        };
        // Table Check Box
        this.handleCheckAll = this.handleCheckAll.bind(this);
        this.handleCheck = this.handleCheck.bind(this);

        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);

        //Clear customs
        this.onClickClearCustoms = this.onClickClearCustoms.bind(this);


        //Filter hide Show
        this.onClickIsFilterShown = this.onClickIsFilterShown.bind(this);
        this.onClickResetFilters = this.onClickResetFilters.bind(this);

        //Search Values
        this.onChangeSearchHouseID = this.onChangeSearchHouseID.bind(this);
        this.onChangeSearchHouseRef = this.onChangeSearchHouseRef.bind(this);
        this.onChangeSearchMasterID = this.onChangeSearchMasterID.bind(this);
        this.onChangeSearchMaster = this.onChangeSearchMaster.bind(this);
        this.onChangeSearchOrderNo = this.onChangeSearchOrderNo.bind(this);
        this.onChangeSearchShipmentETDStart = this.onChangeSearchShipmentETDStart.bind(this);
        this.onChangeSearchShipmentETDEnd = this.onChangeSearchShipmentETDEnd.bind(this);
        this.onChangeSearchOrigin = this.onChangeSearchOrigin.bind(this);
        this.onChangeSearchLastDischargePort = this.onChangeSearchLastDischargePort.bind(this);
        this.onChangeSearchETADischargeStart = this.onChangeSearchETADischargeStart.bind(this);
        this.onChangeSearchETADischargeEnd = this.onChangeSearchETADischargeEnd.bind(this);
        this.onChangeSearchEstCartageDeliveryStart = this.onChangeSearchEstCartageDeliveryStart.bind(this);
        this.onChangeSearchEstCartageDeliveryEnd = this.onChangeSearchEstCartageDeliveryEnd.bind(this);
        this.onChangeSearchActualCartageDeliveryStart = this.onChangeSearchActualCartageDeliveryStart.bind(this);
        this.onChangeSearchActualCartageDeliveryEnd = this.onChangeSearchActualCartageDeliveryEnd.bind(this);
        this.onChangeSearchHawbStatusID = this.onChangeSearchHawbStatusID.bind(this);
        this.onChangeSearchConsignee = this.onChangeSearchConsignee.bind(this);
        this.onChangeSearchDestination = this.onChangeSearchDestination.bind(this);

        //Build Load Drawer
        this.onChangeBuildLoadDrawer = this.onChangeBuildLoadDrawer.bind(this);
        this.onChangeExistingBuildLoadDrawer = this.onChangeExistingBuildLoadDrawer.bind(this);
        this.onClickBuildLoad = this.onClickBuildLoad.bind(this);
        this.closeBuildLoadDrawer = this.closeBuildLoadDrawer.bind(this);
        this.onClickBuildLoadDrawerSave = this.onClickBuildLoadDrawerSave.bind(this);
        this.onClickRemoveFromBuild = this.onClickRemoveFromBuild.bind(this);

    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        this.loadSortFromStorage()


        try {
            var paramHouseRef = queryStringParse(values.houseref)
        }
        catch (e) {
            var paramHouseRef = null
        }
        if (paramHouseRef != null) {
            this.onChangeSearchHouseRef(paramHouseRef)
        }
        try {
            var paramMaster = queryStringParse(values.master)
        }
        catch (e) {
            var paramMaster = null
        }
        if (paramMaster != null) {
            this.onChangeSearchMaster(paramMaster)
        }
        try {
            var paramOrderNo = queryStringParse(values.orderno)
        }
        catch (e) {
            var paramOrderNo = null
        }
        if (paramOrderNo != null) {
            this.onChangeSearchOrderNo(paramOrderNo)
        }
        try {
            var date = queryStringParse(values.shipmentetdstart)
            if (date === null) {
                var paramShipmentETDStart = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramShipmentETDStart = dayAdd
            }
        }
        catch (e) {
            var paramShipmentETDStart = null
        }
        this.onChangeSearchShipmentETDStart(paramShipmentETDStart)
        try {
            var date = queryStringParse(values.shipmentetdend)
            if (date === null) {
                var paramShipmentETDEnd = null
            }
            else {
                var dateValue = new Date(date);
                var dayAdd = moment(dateValue).add(1, 'days')
                var paramShipmentETDEnd = dayAdd
            }
        }
        catch (e) {
            var paramShipmentETDEnd = null
        }
        this.onChangeSearchShipmentETDEnd(paramShipmentETDEnd)
        try {
            var paramHawbStatusID = queryStringParse(values.hawbstatusid)
        }
        catch (e) {
            var paramHawbStatusID = null
        }
        this.onChangeSearchHawbStatusID(paramHawbStatusID)
        try {
            var paramConsignee = queryStringParse(values.consignee)
        }
        catch (e) {
            var paramConsignee = null
        }
        this.onChangeSearchConsignee(paramConsignee)
        try {
            var paramDestination = queryStringParse(values.destination)
        }
        catch (e) {
            var paramDestination = null
        }
        this.onChangeSearchDestination(paramDestination)


    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.warehouseSelection !== this.props.warehouseSelection) {
            this.setState({
                checkedKeys: []
            });
        }
        if (prevProps.customerSelection !== this.props.customerSelection) {
            this.setState({
                checkedKeys: []
            });
        }
    }
    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.UserWarehouseData !== this.props.UserWarehouseData && this.props.UserWarehouseData.length > 0) {
    //         var defaultWarehouse = null
    //         try {
    //             if (this.props.UserWarehouseData.length == 1) {
    //                 defaultWarehouse = this.props.UserWarehouseData[0]['value']
    //             }
    //             else {
    //                 defaultWarehouse = null
    //             }
    //         }
    //         catch (e) {
    //         }
    //         this.onChangeWarehouseSelection(defaultWarehouse)

    //         try {
    //             const values = queryString.parse(this.props.location.search)
    //             var defaultWarehouse = null
    //             try {
    //                 var defaultWarehouse = queryStringParse(values.warehouseid)
    //             }
    //             catch (e) {
    //                 var defaultWarehouse = null
    //             }
    //             this.onChangeWarehouseSelection(defaultWarehouse)
    //         }
    //         catch (e) { }

    //     }
    // }

    // Table Check Box
    handleCheckAll(value, checked, data) {
        const checkedKeys = checked ? [] : data.map(item => item[tableIdCol]);
        this.setState({
            checkedKeys
        });
    }
    handleCheck(value, checked) {
        const currentCheckedRow = this.props.HawbData.filter(x => value === x.HouseID)

        const remainingToLoad = currentCheckedRow[0]['RecoveredPCS'] - currentCheckedRow[0]['LoadedPCS']
        if (remainingToLoad < 1) {
            window.alert('Nothing remaining to load.')
        }
        else {

            const { checkedKeys } = this.state;
            const nextCheckedKeys = checked
                ? [...checkedKeys, value]
                : checkedKeys.filter(item => item !== value);
            this.setState({
                checkedKeys: nextCheckedKeys
            });
        }
    }

    // Table Sort
    saveSortTosessionStorage(sortColumn, sortType) {
        try {
            sessionStorage.setItem('sortcolumn', sortColumn)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortType', sortType)
        }
        catch (e) { }
        try {
            sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
        }
        catch (e) { }
    }
    loadSortFromStorage() {
        var sortColumn = null
        var sortType = null
        var pathName = null
        try {
            sortColumn = sessionStorage.getItem('sortcolumn')
        }
        catch (e) { }
        try {
            sortType = sessionStorage.getItem('sortType')
        }
        catch (e) { }
        try {
            pathName = sessionStorage.getItem('sortURLPathname')
        }
        catch (e) { }
        if (pathName === this.props.location.pathname) {

            this.handleSortColumn(sortColumn, sortType)
        }
    }

    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        this.saveSortTosessionStorage(sortColumn, sortType)

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 100);
    }


    // componentDidMount() {
    // this.updateParamsFromURL()
    // }
    // updateParamsFromURL = () => {

    //     const values = queryString.parse(this.props.location.search)

    // }


    //Is filter shown
    onClickIsFilterShown(e, isFilterShown) {
        if (isFilterShown) {
            this.setState({
                isFilterShown: false
            });
        }
        else {
            this.setState({
                isFilterShown: true
            });
        }
    }


    //Reset Filters
    onClickResetFilters(e) {

        this.setState({
            isFilterShown: false
        });

        // var defaultWarehouse = null
        // try {
        //     if (this.props.UserWarehouseData.length == 1) {
        //         defaultWarehouse = this.props.UserWarehouseData[0]['value']
        //     }
        //     else {
        //         defaultWarehouse = null
        //     }
        // }
        // catch (e) {
        // }

        this.setState({
            searchHouseID: null,
            searchHouseRef: null,
            searchMasterID: null,
            searchMaster: null,
            searchOrderNo: null,
            searchShipmentETDStart: null,
            searchShipmentETDEnd: null,
            searchOrigin: null,
            searchLastDischargePort: null,
            searchETADischargeStart: null,
            searchETADischargeEnd: null,
            searchEstCartageDeliveryStart: null,
            searchEstCartageDeliveryEnd: null,
            searchActualCartageDeliveryStart: null,
            searchActualCartageDeliveryEnd: null,
            searchHawbStatusID: 1,
            searchConsignee: null,
            searchDestination: null

            // warehouseSelection: defaultWarehouse
        });

        this.props.history.push({
            search: 'hawbstatusid=1'
        });

        setTimeout(() => {
            this.setState({
                isFilterShown: true
            });
        }, 500);

    }


    //Apply Filters
    onClickApplySearch(e) {

        this.setState({
            searchMaster: '',
            searchHouseRef: '',
            searchOrderNo: '',
        });

        try {
            this.props.history.push({
                search:
                    'houseid=' + this.state.searchHouseID + '&' +
                    // 'houseref=' + this.state.searchHouseRef + '&' +
                    'houseref=null' + '&' +
                    'masterid=' + this.state.searchMasterID + '&' +
                    // 'master=' + this.state.searchMaster + '&' +
                    'master=null' + '&' +
                    'shipmentetdstart=' + formatDateForUrlSearch(this.state.searchShipmentETDStart) + '&' +
                    'shipmentetdend=' + formatDateForUrlSearch(this.state.searchShipmentETDEnd) + '&' +
                    'origin=' + this.state.searchOrigin + '&' +
                    'lastdischargeport=' + this.state.searchLastDischargePort + '&' +
                    'etadischargestart=' + formatDateForUrlSearch(this.state.searchETADischargeStart) + '&' +
                    'etadischargeend=' + formatDateForUrlSearch(this.state.searchETADischargeEnd) + '&' +
                    'estcartagedeliverystart=' + formatDateForUrlSearch(this.state.searchEstCartageDeliveryStart) + '&' +
                    'estcartagedeliveryend=' + formatDateForUrlSearch(this.state.searchEstCartageDeliveryEnd) + '&' +
                    'actualcartagedeliverystart=' + formatDateForUrlSearch(this.state.searchActualCartageDeliveryStart) + '&' +
                    'actualcartagedeliveryend=' + formatDateForUrlSearch(this.state.searchActualCartageDeliveryEnd) + '&' +
                    'hawbstatusid=' + this.state.searchHawbStatusID + '&' +
                    'consignee=' + this.state.searchConsignee + '&' +
                    'destination=' + this.state.searchDestination
                // 'warehouseid=' + this.state.warehouseSelection
            });
        }
        catch (e) { }
    }

    onSearchClearOtherFilters() {
        this.setState({
            searchShipmentETDStart: null,
            searchShipmentETDEnd: null,
            searchOrigin: null,
            searchLastDischargePort: null,
            searchETADischargeStart: null,
            searchETADischargeEnd: null,
            searchEstCartageDeliveryStart: null,
            searchEstCartageDeliveryEnd: null,
            searchActualCartageDeliveryStart: null,
            searchActualCartageDeliveryEnd: null,
            searchHawbStatusID: null,
            searchConsignee: null,
            searchDestination: null
        });

    }

    //Search onChange
    onChangeSearchHouseID(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchHouseID: e
        });
    }
    onChangeSearchHouseRef(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchHouseRef: e,
            searchMaster: '',
            searchOrderNo: '',
        });
    }
    onChangeSearchMasterID(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchMasterID: e
        });
    }
    onChangeSearchMaster(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchMaster: e,
            searchHouseRef: '',
            searchOrderNo: '',
        });
    }
    onChangeSearchOrderNo(e) {
        this.onSearchClearOtherFilters()
        this.setState({
            searchOrderNo: e,
            searchHouseRef: '',
            searchMaster: '',
        });
    }


    onChangeSearchShipmentETDStart(e) {
        this.setState({
            searchShipmentETDStart: e
        });
    }
    onChangeSearchShipmentETDEnd(e) {
        this.setState({
            searchShipmentETDEnd: e
        });
    }
    onChangeSearchOrigin(e) {
        this.setState({
            searchOrigin: e
        });
    }
    onChangeSearchLastDischargePort(e) {
        this.setState({
            searchLastDischargePort: e
        });
    }
    onChangeSearchETADischargeStart(e) {
        this.setState({
            searchETADischargeStart: e
        });
    }
    onChangeSearchETADischargeEnd(e) {
        this.setState({
            searchETADischargeEnd: e
        });
    }
    onChangeSearchEstCartageDeliveryStart(e) {
        this.setState({
            searchEstCartageDeliveryStart: e
        });
    }
    onChangeSearchEstCartageDeliveryEnd(e) {
        this.setState({
            searchEstCartageDeliveryEnd: e
        });
    }
    onChangeSearchActualCartageDeliveryStart(e) {
        this.setState({
            searchActualCartageDeliveryStart: e
        });
    }
    onChangeSearchActualCartageDeliveryEnd(e) {
        this.setState({
            searchActualCartageDeliveryEnd: e
        });
    }
    onChangeSearchHawbStatusID(e) {
        this.setState({
            searchHawbStatusID: e
        });
    }
    onChangeSearchConsignee(e) {
        this.setState({
            searchConsignee: e
        });
    }
    onChangeSearchDestination(e) {
        this.setState({
            searchDestination: e
        });
    }




    //On Click Details
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData[tableIdCol]
            history.push({
                pathname: 'housedetails',
                search: 'houseid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }


    onClickClearCustoms(rowData, keyColumn) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
            if (rowData.CustomsCleared) {
                window.alert('Customs already cleared.')
            }
            else {
                this.props.hawbRecoveryPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    null,
                    formatNumberForAPI(rowData.HouseID),
                    null,
                    null,
                    null,
                    null,
                    1,
                    this.props.warehouseSelection,
                    this.props.customerSelection
                )
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }





    //Build Load Drawer
    onClickBuildLoad(e) {
        if (this.state.checkedKeys.length > 0) {
            var buildLoadArray = {};
            buildLoadArray = this.props.HawbData.filter(x => this.state.checkedKeys.includes(x.HouseID))

            var newLoadDict = {}
            newLoadDict['PickupDate'] = null
            newLoadDict['DeliveryDate'] = null
            newLoadDict['Carrier'] = null
            newLoadDict['BOL'] = null
            newLoadDict['Trailer'] = null
            newLoadDict['Seal'] = null

            var customsNotCleared = false;

            for (let k in buildLoadArray) {

                if (buildLoadArray[k]['CustomsCleared'] != true) {
                    customsNotCleared = true
                }

                buildLoadArray[k]['NewRecoveredPCS'] = replaceNegativeWithZero(buildLoadArray[k]['RecoveredPCS'] - buildLoadArray[k]['LoadedPCS'])
                buildLoadArray[k]['NewPallets'] = replaceNegativeWithZero(buildLoadArray[k]['Pallets'] - buildLoadArray[k]['LoadedPallets'])

                // buildLoadArray[k]['NewRecoveredPCS'] = 0
                // buildLoadArray[k]['NewPallets'] = 0

            }

            if (customsNotCleared) {
                if (window.confirm('Warning: contains customs not cleared. Proceed?')) {
                    this.setState({
                        // unsavedBuildLoadChanges: true,
                        BuildLoadDict: buildLoadArray,
                        NewLoadData: newLoadDict,
                        showBuildLoadDrawer: true,
                    });
                }
                else {
                    this.setBuildLoadDrawerToClose()
                }

            }
            else {
                this.setState({
                    // unsavedBuildLoadChanges: true,
                    BuildLoadDict: buildLoadArray,
                    NewLoadData: newLoadDict,
                    showBuildLoadDrawer: true,
                });
            }
        }
        else {
            window.alert('Please select rows.')
        }
    }
    onChangeBuildLoadDrawer = (event, label) => {
        var currentLoadData = this.state.NewLoadData
        currentLoadData[label] = event
        this.setState({
            unsavedBuildLoadChanges: true,
            NewLoadData: currentLoadData
        });

    };
    onChangeExistingBuildLoadDrawer = (event, item, label) => {
        setTimeout(() => {
            this.setState({
                existingDataChangeLoading: true
            });
        }, 50);


        var houseId = item.HouseID;
        var itemIndex = this.state.BuildLoadDict.findIndex(({ HouseID }) => HouseID === houseId);
        var currentBuildLoadDict = this.state.BuildLoadDict

        currentBuildLoadDict[itemIndex][label] = isNumeric(event)

        this.setState({
            unsavedBuildLoadChanges: true,
            BuildLoadDict: currentBuildLoadDict,

            existingDataChangeLoading: false
        });
    };
    onClickRemoveFromBuild(e, item) {

        // setTimeout(() => {
        //     this.setState({
        //         showBuildLoadDrawer: false
        //     });
        // }, 50);

        var prevBuild = {}
        prevBuild = this.state.BuildLoadDict

        var houseId = item.HouseID;
        var itemIndex = this.state.BuildLoadDict.findIndex(({ HouseID }) => HouseID === houseId);
        prevBuild.splice(itemIndex, 1);

        setTimeout(() => {
            this.setState({
                BuildLoadDict: prevBuild
            });
        }, 50);

        // setTimeout(() => {
        //     this.setState({
        //         showBuildLoadDrawer: true
        //     });
        // }, 50);
        this.forceUpdate()
    }
    onClickBuildLoadDrawerSave(e, totalRecoveredNum, totalPalleteNum) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
            // if (this.state.NewLoadData.Carrier == null || this.state.NewLoadData.Carrier == '') {
            //     window.alert('Please enter Carrier')
            // }
            // else if (this.state.NewLoadData.Trailer == null || this.state.NewLoadData.Trailer == '') {
            //     window.alert('Please enter Trailer')
            // }

            // if (window.confirm('Save Data?')) {

            const currentListHawb = this.state.BuildLoadDict
            const newHawbToPost = currentListHawb.map(({ HouseID, NewRecoveredPCS, NewPallets }) => ({ HouseID, NewRecoveredPCS, NewPallets }));


            var hawbString = JSON.stringify(newHawbToPost)

            try {
                if (this.state.NewLoadData.PickupDate != null) {
                    var paramPostPickupDate = "'" + moment(this.state.NewLoadData.PickupDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostPickupDate = null
                }
            }
            catch (e) {
                var paramPostPickupDate = null
            }
            try {
                if (this.state.NewLoadData.DeliveryDate != null) {
                    var paramPostDeliveryDate = "'" + moment(this.state.NewLoadData.DeliveryDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostDeliveryDate = null
                }
            }
            catch (e) {
                var paramPostDeliveryDate = null
            }
            try {
                // var paramPostCarrier = "'" + this.state.NewLoadData.Carrier + "'"
                var paramPostCarrier = formatStringForAPI(this.state.NewLoadData.Carrier)
            }
            catch (e) {
                var paramPostCarrier = null
            }
            try {
                // var paramPostBOL = "'" + this.state.NewLoadData.BOL + "'"
                var paramPostBOL = formatStringForAPI(this.state.NewLoadData.BOL)
            }
            catch (e) {
                var paramPostBOL = null
            }
            try {
                // var paramPostTrailer = "'" + this.state.NewLoadData.Trailer + "'"
                var paramPostTrailer = formatStringForAPI(this.state.NewLoadData.Trailer)
            }
            catch (e) {
                var paramPostTrailer = null
            }
            try {
                // var paramPostSeal = "'" + this.state.NewLoadData.Seal + "'"
                var paramPostSeal = formatStringForAPI(this.state.NewLoadData.Seal)
            }
            catch (e) {
                var paramPostSeal = null
            }
            try {
                var paramPostTotalPCS = totalRecoveredNum
            }
            catch (e) {
                var paramPostTotalPCS = null
            }
            try {
                var paramPostTotalPallets = totalPalleteNum
            }
            catch (e) {
                var paramPostTotalPallets = null
            }

            try {
                var paramPostLoadStatusID = 1
            }
            catch (e) {
                var paramPostLoadStatusID = 1
            }
            try {
                var paramPostLoadHawbData = "'" + hawbString + "'"
            }
            catch (e) {
                var paramPostLoadHawbData = null
            }





            try {
                var paramPostMC = formatStringForAPI(this.state.NewLoadData.MC)
            }
            catch (e) {
                var paramPostMC = null
            }
            try {
                var paramPostDOT = formatStringForAPI(this.state.NewLoadData.DOT)
            }
            catch (e) {
                var paramPostDOT = null
            }
            try {
                var paramPostDriver = formatStringForAPI(this.state.NewLoadData.Driver)
            }
            catch (e) {
                var paramPostDriver = null
            }
            try {
                var paramPostTruck = formatStringForAPI(this.state.NewLoadData.Truck)
            }
            catch (e) {
                var paramPostTruck = null
            }
            try {
                var paramPostTruckPlate = formatStringForAPI(this.state.NewLoadData.TruckPlate)
            }
            catch (e) {
                var paramPostTruckPlate = null
            }
            try {
                var paramPostTrailerPlate = formatStringForAPI(this.state.NewLoadData.TrailerPlate)
            }
            catch (e) {
                var paramPostTrailerPlate = null
            }
            try {
                var paramPostNotes = formatStringForAPI(this.state.NewLoadData.Notes)
            }
            catch (e) {
                var paramPostNotes = null
            }



            this.props.loadPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, paramPostPickupDate, paramPostDeliveryDate, paramPostCarrier, paramPostBOL, paramPostTrailer, paramPostSeal, paramPostTotalPCS, paramPostTotalPallets, paramPostLoadStatusID, paramPostLoadHawbData, null, null, null, this.props.warehouseSelection, this.props.customerSelection, paramPostMC, paramPostDOT, paramPostDriver, paramPostTruck, paramPostTruckPlate, paramPostTrailerPlate, paramPostNotes) 

            this.setState({
                unsavedBuildLoadChanges: false,
                showBuildLoadDrawer: false
            });
            // }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }
    closeBuildLoadDrawer() {
        if (this.state.unsavedBuildLoadChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setBuildLoadDrawerToClose()
            }
        }
        else {
            this.setBuildLoadDrawerToClose()
        }
    }

    setBuildLoadDrawerToClose() {
        this.setState({
            BuildLoadDict: {},
            NewLoadData: {},
            unsavedBuildLoadChanges: false,
            showBuildLoadDrawer: false
        });
    }




    render() {
        const { checkedKeys, sortColumn, sortType } = this.state;

        let hideShowSearch;
        hideShowSearch = <Row style={{ marginBottom: '5px' }}>

            <Col md={24} sm={24} >

                <p>
                    {RButtonHideShowFilter(this.props.isMobile, this.state.isFilterShown, 'Filters', this.onClickIsFilterShown, this.onClickResetFilters)}
                </p>

            </Col>
        </Row>

        // let warehouseSelectionDiv;
        // if (this.props.isUserWarehouseLoading) {
        //     warehouseSelectionDiv =
        //         <div align="center">
        //             <section className="panel">
        //             </section>
        //             <Loader
        //                 type="TailSpin"
        //                 color="#3396E5"
        //                 height="50"
        //                 width="50"
        //             />
        //         </div>
        // }
        // else {

        //     warehouseSelectionDiv =
        //         <Row>
        //             {RSelectPicker(
        //                 this.props.isMobile,
        //                 this.props.UserWarehouseData,
        //                 this.state.warehouseSelection,
        //                 'Warehouse',
        //                 this.onChangeWarehouseSelection,
        //                 this.props.history
        //             )}
        //         </Row>


        // }

        let searchDiv;

        if (this.state.isFilterShown) {
            searchDiv = <Row  >
                <Col md={24} sm={24} >
                    <Panel bordered style={{ marginBottom: '10px' }}>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'Master',
                                this.onChangeSearchMaster,
                                this.props.history,
                                'master',
                                this.state.searchMaster
                            )}
                        </Row>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'House Ref',
                                this.onChangeSearchHouseRef,
                                this.props.history,
                                'houseref',
                                this.state.searchHouseRef
                            )}
                        </Row>
                        <Row>
                            {RSearchInputNumberAsString(
                                this.props.isMobile,
                                'PO #',
                                this.onChangeSearchOrderNo,
                                this.props.history,
                                'orderno',
                                this.state.searchOrderNo
                            )}
                        </Row>
                    </Panel>
                    <Panel bordered style={{ marginBottom: '10px' }}>


                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                hawbStatus,
                                this.state.searchHawbStatusID,
                                'Status',
                                this.onChangeSearchHawbStatusID,
                                this.props.history,
                                mountRef
                            )}
                        </Row>
                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.props.HawbConsigneeData,
                                this.state.searchConsignee,
                                'Consignee',
                                this.onChangeSearchConsignee,
                                this.props.history,
                                mountRef
                            )}
                        </Row>
                        <Row>
                            {RSelectPicker(
                                this.props.isMobile,
                                this.props.HawbDestinationData,
                                this.state.searchDestination,
                                'Destination',
                                this.onChangeSearchDestination,
                                this.props.history,
                                mountRef
                            )}
                        </Row>
                        <Row>
                            {RDatePickerRange(
                                this.props.isMobile,
                                'Recovery Date',
                                this.onChangeSearchShipmentETDStart,
                                this.onChangeSearchShipmentETDEnd,
                                this.state.searchShipmentETDStart,
                                this.state.searchShipmentETDEnd
                            )}
                        </Row>

                        <Row>
                            <Button onClick={(e) => { this.onClickApplySearch(e) }} style={{ marginRight: '10px' }}> Apply </Button>
                            <Button onClick={(e) => { this.onClickResetFilters(e) }}> Reset </Button>
                        </Row>
                    </Panel>
                </Col>
            </Row>
        }
        else if (!this.state.isFilterShown) {
            searchDiv = <div></div>
        }

        let mawbDiv;
        let rTable;
        if (this.props.isHawbLoading) {
            rTable = <div></div>
            mawbDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.HawbData) !== 'undefined' && this.props.HawbData != null) {
            const values = queryString.parse(this.props.location.search)
            try {
                var paramHawbStatusID = queryStringParse(values.hawbstatusid)
            }
            catch (e) {
                var paramHawbStatusID = null
            }

            rTable = RTable(
                this.props.HawbData,        // tableData,
                HawbDataElements,  // DataElements,
                sortColumn,                 // sortColumn,
                sortType,                   // sortType,
                this.props.isHawbLoading,         // loading,
                this.handleSortColumn,      // handleSortColumn,
                this.onClickDetails,        // onClickDetails,
                // true,                      // checkColEntry,
                (paramHawbStatusID == 1 || paramHawbStatusID == null ? true : false), // checkColEntry,
                tableIdCol,                 // keyColumn,
                checkedKeys,                       // checkedKeys,
                this.handleCheckAll,                       // handleCheckAll,
                this.handleCheck,                       // handleCheck,
                false,                      // boolSelectButton,
                null,     // onClickSelect,
                null,                       // selectButtonText,
                this.props.isMobile,        // isMobile,
                this.props.history,         // history,
                null,                        // highlightRowKey

                false,                        // editLink,
                null,                   // editLinkText,
                null                        // editLinkOnClick

                // true,                        // editLink,
                // 'Clear Customs',                   // editLinkText,
                // this.onClickClearCustoms                        // editLinkOnClick
            )

            mawbDiv = <div>

                {(this.props.isMobile == true) ? <div></div> :

                    <div>

                        <Row>

                            {paramHawbStatusID == 1 || paramHawbStatusID == null ? <Button style={{ float: 'left' }} onClick={(e) => { this.onClickBuildLoad(e) }}>Build Load Selected</Button> : ''}
                            <ExcelFile element={<Button style={{ float: 'right' }}>Export Excel</Button>} filename={'Warehouse_' + currentDateTime}>

                                <ExcelSheet data={this.props.HawbData} name="Warehouse">

                                    {
                                        HawbDataElements.map(
                                            ({ dBKey, displayName }, idx) => {
                                                return (
                                                    <ExcelColumn label={displayName} value={dBKey} />
                                                );
                                            }
                                        )
                                    }

                                </ExcelSheet>
                            </ExcelFile>

                        </Row>
                    </div>
                }

            </div>





        }
        else {
            rTable = <div></div>
            mawbDiv = <div></div>
        }


        let totalRecoveredNumber;
        let totalPalletNumber;
        if (!this.state.existingDataChangeLoading) {
            try {
                totalRecoveredNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewRecoveredPCS, 0)
            }
            catch (e) {
                totalRecoveredNumber = 0
            }

            try {
                totalPalletNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewPallets, 0)
            }
            catch (e) {
                totalPalletNumber = 0
            }
        }
        else {
            try {
                totalRecoveredNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewRecoveredPCS, 0)
            }
            catch (e) {
                totalRecoveredNumber = 0
            }
            try {
                totalPalletNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.NewPallets, 0)
            }
            catch (e) {
                totalPalletNumber = 0
            }
        }


        let buildLoadDrawer;


        if (this.props.isHawbLoading) {
            totalRecoveredNumber = 0
            buildLoadDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else if (typeof (this.state.BuildLoadDict) !== 'undefined' && this.state.BuildLoadDict != null && this.state.BuildLoadDict.length > 0 && this.state.showBuildLoadDrawer) {
            // totalRecoveredNumber = this.state.BuildLoadDict.reduce((a, v) => a = a + v.RecoveredPCS, 0)
            buildLoadDrawer = <BuildLoadDrawer {...this.props} state={this.state} totalRecoveredNum={totalRecoveredNumber} totalPalleteNum={totalPalletNumber} onChange={this.onChangeBuildLoadDrawer} onChangeExisting={this.onChangeExistingBuildLoadDrawer} confirm={this.onClickBuildLoadDrawerSave} close={this.closeBuildLoadDrawer} remove={this.onClickRemoveFromBuild} />
        }



        return (
            <div>
                <h3>
                    Airfreight - Warehouse
                </h3>

                {hideShowSearch}
                {searchDiv}
                {mawbDiv}
                {rTable}

                {buildLoadDrawer}
            </div>
        );

    }
}

export default withRouter(Warehouse);

