import React, {
    PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import { Row, Col, Panel, PanelGroup, Timeline, Button, ButtonToolbar } from 'rsuite';
//  import {PoProductsDataElements} from '../../dataComponentsXdock/PoProductsDataElements';
//  import RTable from '../../componentsGeneral/Rsuite/Table/RTable';
import { formatNumberForAPI, isNumeric, formatStringForAPI, replaceNegativeWithZero } from '../../../resources/logic/functions';
import LoadHawbDataElements from '../../dataComponentsXdock/LoadHawbDataElements';
import RTable from '../../componentsGeneral/Rsuite/Table/RTable';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import AddToLoadDrawer from './AddToLoadDrawer';
import AddNewModal from './AddNewModal';
import EditLoadDrawer from './EditLoadDrawer';
import LoadExportModal from '../LoadExport/LoadExportModal';
import { appAPI } from '../../../app/appConfig';
import RTableGeneral from '../../componentsGeneral/Rsuite/Table/RTableGeneral';
import XdockLoadFileUploadsDataElements from '../../dataComponentsXdock/XdockLoadFileUploadsDataElements';
import RUploaderGeneral from '../../componentsGeneral/Rsuite/Uploader/RUploaderGeneral';


//For excel export
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


const tableIdCol = "HouseID"

//For Rsuite NavBar
const styles = {
    marginBottom: 50
};


class LoadDetails extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            //Edit Load Drawer
            showEditLoadDrawer: false,
            editLoadItem: {},
            unsavedEditLoadChanges: false,


            //Build Load Drawer
            showBuildLoadDrawer: false,
            item: {},
            HouseSearch: null,
            MasterSearch: null,
            addNewModalShown: false,

            //Export Modal
            isModalShown: false,
            loadDataExportModal: {}

        };
        // Table Sort
        this.handleSortColumn = this.handleSortColumn.bind(this);
        this.handleSortColumnDrawer = this.handleSortColumnDrawer.bind(this);

        // this.onClickEditRecovery = this.onClickEditRecovery.bind(this);
        this.onClickClearCustoms = this.onClickClearCustoms.bind(this);
        this.onClickRemoveFromLoad = this.onClickRemoveFromLoad.bind(this);

        //Edit Load Drawer
        this.onChangeEditLoadDrawer = this.onChangeEditLoadDrawer.bind(this);
        this.onClickSaveEditLoadDrawer = this.onClickSaveEditLoadDrawer.bind(this);
        this.openEditLoadDrawer = this.openEditLoadDrawer.bind(this);
        this.closeEditLoadDrawer = this.closeEditLoadDrawer.bind(this);

        //Build Load Drawer
        this.onChangeBuildLoadDrawer = this.onChangeBuildLoadDrawer.bind(this);
        this.onClickAddToLoad = this.onClickAddToLoad.bind(this);
        this.closeBuildLoadDrawer = this.closeBuildLoadDrawer.bind(this);
        this.onClickAdd = this.onClickAdd.bind(this);
        this.onClickSearch = this.onClickSearch.bind(this);
        this.onClickReset = this.onClickReset.bind(this);
        this.handleOpenAddNewModal = this.handleOpenAddNewModal.bind(this);
        this.handleCloseAddNewModal = this.handleCloseAddNewModal.bind(this);
        this.handleSaveAddNewModal = this.handleSaveAddNewModal.bind(this);
        this.onChangeExistingBuildLoadDrawer = this.onChangeExistingBuildLoadDrawer.bind(this);

        //Export Modal
        this.onClickExportLoad = this.onClickExportLoad.bind(this);
        this.handleCloseExportModal = this.handleCloseExportModal.bind(this);


        //Delete Load
        this.onClickDeleteLoad = this.onClickDeleteLoad.bind(this);


        //File Upload
        this.onClickDownloadFileUpload = this.onClickDownloadFileUpload.bind(this);
        this.onClickDeleteFileUpload = this.onClickDeleteFileUpload.bind(this);

    }





    handleSortColumn(sortColumn, sortType) {
        this.setState({
            loading: true
        });

        setTimeout(() => {
            this.setState({
                sortColumn,
                sortType,
                loading: false
            });
        }, 500);
    }
    handleSortColumnDrawer(sortColumnDrawer, sortTypeDrawer) {
        this.setState({
            loading: true
        });

        setTimeout(() => {
            this.setState({
                sortColumnDrawer,
                sortTypeDrawer,
                loading: false
            });
        }, 500);
    }


    onClickClearCustoms(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
            if (window.confirm('Clear Customs?')) {
                this.props.hawbRecoveryPost(
                    this.props.currentUserEmail,
                    this.props.userSignInData.AuthToken,
                    null,
                    formatNumberForAPI(this.props.LoadData[0].HouseID),
                    null,
                    null,
                    null,
                    null,
                    1,
                    this.props.warehouseSelection,
                    this.props.customerSelection
                )
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }

    //On Click Details
    onClickDetails(e, rowData, history) {
        try {
            var paramID = rowData["HouseID"]
            history.push({
                pathname: 'housedetails',
                search: 'houseid=\'' + paramID + '\''
            });
        }
        catch (e) {
        }
    }

    onClickRemoveFromLoad(rowData, keyColumn) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
            if (window.confirm('Remove from load?')) {
                this.props.loadRemovePost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData["LoadID"], rowData["HouseID"], rowData["PCS"], rowData["Pallets"], this.props.warehouseSelection, this.props.customerSelection)
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }






    onClickAddToLoad(e) {
        var buildLoadArray = {};
        buildLoadArray = this.props.HawbData

        var newLoadDict = {}
        newLoadDict['HouseSearch'] = null
        newLoadDict['MasterSearch'] = null

        for (let k in buildLoadArray) {
            buildLoadArray[k]['NewRecoveredPCS'] = buildLoadArray[k]['RecoveredPCS'] - buildLoadArray[k]['LoadedPCS']
            buildLoadArray[k]['NewPallets'] = replaceNegativeWithZero(buildLoadArray[k]['Pallets'] - buildLoadArray[k]['LoadedPallets'])
        }
        this.setState({
            BuildLoadDict: buildLoadArray,
            showBuildLoadDrawer: true,
        });
    }




    //Edit Load Drawer
    openEditLoadDrawer(e) {
        var editLoadData = {}
        // editLoadData = this.props.LoadData[0]

        editLoadData['LoadID'] = this.props.LoadData[0].LoadID
        editLoadData['PickupDate'] = this.props.LoadData[0].PickupDate
        editLoadData['DeliveryDate'] = this.props.LoadData[0].DeliveryDate
        editLoadData['Carrier'] = this.props.LoadData[0].Carrier
        editLoadData['BOL'] = this.props.LoadData[0].BOL
        editLoadData['Trailer'] = this.props.LoadData[0].Trailer
        editLoadData['Seal'] = this.props.LoadData[0].Seal

        editLoadData['MC'] = this.props.LoadData[0].MC
        editLoadData['DOT'] = this.props.LoadData[0].DOT
        editLoadData['Driver'] = this.props.LoadData[0].Driver
        editLoadData['Truck'] = this.props.LoadData[0].Truck
        editLoadData['TruckPlate'] = this.props.LoadData[0].TruckPlate
        editLoadData['TrailerPlate'] = this.props.LoadData[0].TrailerPlate
        editLoadData['Notes'] = this.props.LoadData[0].Notes

        this.setState({
            unsavedEditLoadChanges: false,
            editLoadItem: editLoadData,
            showEditLoadDrawer: true
        });
    }

    onChangeEditLoadDrawer(event, label) {
        var currentLoadData = {}
        currentLoadData = this.state.editLoadItem
        currentLoadData[label] = event
        this.setState({
            unsavedEditLoadChanges: true,
            editLoadItem: currentLoadData
        });
    }

    onClickSaveEditLoadDrawer(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            try {
                var paramPostLoadID = this.state.editLoadItem.LoadID
            }
            catch (e) {
                var paramPostLoadID = null
            }

            try {
                if (this.state.editLoadItem.PickupDate != null) {
                    var paramPostPickupDate = "'" + moment(this.state.editLoadItem.PickupDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostPickupDate = null
                }
            }
            catch (e) {
                var paramPostPickupDate = null
            }
            try {
                if (this.state.editLoadItem.DeliveryDate != null) {
                    var paramPostDeliveryDate = "'" + moment(this.state.editLoadItem.DeliveryDate).format('YYYY-MM-DD') + "'"
                }
                else {
                    var paramPostDeliveryDate = null
                }
            }
            catch (e) {
                var paramPostDeliveryDate = null
            }
            try {
                var paramPostCarrier = formatStringForAPI(this.state.editLoadItem.Carrier)
            }
            catch (e) {
                var paramPostCarrier = null
            }
            try {
                var paramPostBOL = formatStringForAPI(this.state.editLoadItem.BOL)
            }
            catch (e) {
                var paramPostBOL = null
            }
            try {
                var paramPostTrailer = formatStringForAPI(this.state.editLoadItem.Trailer)
            }
            catch (e) {
                var paramPostTrailer = null
            }
            try {
                var paramPostSeal = formatStringForAPI(this.state.editLoadItem.Seal)
            }
            catch (e) {
                var paramPostSeal = null
            }
            // try {
            //     var paramPostTotalPCS = totalRecoveredNum
            // }
            // catch (e) {
            //     var paramPostTotalPCS = null
            // }
            // try {
            //     var paramPostTotalPallets = totalPalleteNum
            // }
            // catch (e) {
            //     var paramPostTotalPallets = null
            // }

            // try {
            //     var paramPostLoadStatusID = 1
            // }
            // catch (e) {
            //     var paramPostLoadStatusID = null
            // }



            try {
                var paramPostMC = formatStringForAPI(this.state.editLoadItem.MC)
            }
            catch (e) {
                var paramPostMC = null
            }
            try {
                var paramPostDOT = formatStringForAPI(this.state.editLoadItem.DOT)
            }
            catch (e) {
                var paramPostDOT = null
            }
            try {
                var paramPostDriver = formatStringForAPI(this.state.editLoadItem.Driver)
            }
            catch (e) {
                var paramPostDriver = null
            }
            try {
                var paramPostTruck = formatStringForAPI(this.state.editLoadItem.Truck)
            }
            catch (e) {
                var paramPostTruck = null
            }
            try {
                var paramPostTruckPlate = formatStringForAPI(this.state.editLoadItem.TruckPlate)
            }
            catch (e) {
                var paramPostTruckPlate = null
            }
            try {
                var paramPostTrailerPlate = formatStringForAPI(this.state.editLoadItem.TrailerPlate)
            }
            catch (e) {
                var paramPostTrailerPlate = null
            }
            try {
                var paramPostNotes = formatStringForAPI(this.state.editLoadItem.Notes)
            }
            catch (e) {
                var paramPostNotes = null
            }


            this.props.loadPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, paramPostLoadID, paramPostPickupDate, paramPostDeliveryDate, paramPostCarrier, paramPostBOL, paramPostTrailer, paramPostSeal, null, null, null, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection, paramPostMC, paramPostDOT, paramPostDriver, paramPostTruck, paramPostTruckPlate, paramPostTrailerPlate, paramPostNotes)

            this.setState({
                showEditLoadDrawer: false,
                unsavedEditLoadChanges: false,
                editLoadItem: {}
            });
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }

    }

    closeEditLoadDrawer(e) {
        if (this.state.unsavedEditLoadChanges) {
            if (window.confirm('Leave without saving changes?')) {
                this.setState({
                    showEditLoadDrawer: false,
                    unsavedEditLoadChanges: false,
                    editLoadItem: {}
                });
            }
        }
        else {
            this.setState({
                showEditLoadDrawer: false,
                unsavedEditLoadChanges: false,
                editLoadItem: {}
            });
        }
    }











    //Build Load Drawer
    onClickBuildLoad(e) {

        this.setState({
            showBuildLoadDrawer: true
        });

    }
    onChangeBuildLoadDrawer = (event, label) => {

        var houseState = null
        if (label == 'HouseSearch') {
            houseState = event
        }
        var masterState = null
        if (label == 'MasterSearch') {
            masterState = event
        }

        this.setState({
            HouseSearch: houseState,
            MasterSearch: masterState

        });

    };


    onClickAdd(e, item) {
        var newItem = {};
        newItem = item

        newItem['NewRecoveredPCS'] = item['RecoveredPCS'] - item['LoadedPCS']
        newItem['NewPallets'] = replaceNegativeWithZero(item['Pallets'] - item['LoadedPallets'])

        this.setState({
            item: newItem
        });
        this.handleOpenAddNewModal()
    }

    closeBuildLoadDrawer() {
        this.setBuildLoadDrawerToClose()
    }

    setBuildLoadDrawerToClose() {
        this.setState({
            showBuildLoadDrawer: false
            // item: {}
        });
    }



    onClickSearch(e, searchType) {
        if (searchType == 'HouseSearch') {
            this.setState({
                MasterSearch: null,
                item: {}
            });

            this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, formatStringForAPI(this.state.HouseSearch), null, null, null, null, null, null, null, null, null, null, null, null, 1, null, null, this.props.warehouseSelection, null, null, this.props.customerSelection);

        }
        else if (searchType == 'MasterSearch') {
            this.setState({
                HouseSearch: null,
                item: {}
            });

            this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, formatStringForAPI(this.state.MasterSearch), null, null, null, null, null, null, null, null, null, null, 1, null, null, this.props.warehouseSelection, null, null, this.props.customerSelection);
        }

    }


    onClickReset(e, searchType) {
        this.setState({
            HouseSearch: null,
            MasterSearch: null,
            item: {}
        });
        this.props.hawbGet(this.props.currentUserEmail, this.props.userSignInData.AuthToken, null, null, null, null, null, null, null, null, null, null, null, null, null, null, 1, null, null, this.props.warehouseSelection, null, null, this.props.customerSelection);
    }


    handleOpenAddNewModal() {
        this.setState({
            addNewModalShown: true
        });
    }
    handleCloseAddNewModal() {
        this.setState({
            addNewModalShown: false
        });
    }


    onChangeExistingBuildLoadDrawer = (event, item, label) => {
        var existingItem = this.state.item

        existingItem[label] = isNumeric(event)

        this.setState({
            item: existingItem
        });
    };


    handleSaveAddNewModal() {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {
            this.props.loadPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.LoadData[0].LoadID, null, null, null, null, null, null, null, null, null, null, this.state.item.HouseID, this.state.item.NewRecoveredPCS, this.state.item.NewPallets, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null)

            this.handleCloseAddNewModal()

            this.setBuildLoadDrawerToClose()
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }



    //Export Modal
    onClickExportLoad(e) {
        this.setState({
            loadDataExportModal: this.props.LoadData[0],
            isModalShown: true
        });

    }
    handleCloseExportModal() {
        this.setState({
            isModalShown: false
        });
    }


    //Delete Load
    onClickDeleteLoad(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            if (window.confirm('Are you sure you want to delete this load?')) {
                this.props.loadPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.LoadData[0].LoadID, null, null, null, null, null, null, null, null, 4, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null)
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }

    //Depart Load
    onClickDepartLoad(e) {
        if (typeof (this.props.warehouseSelection) === 'number' && typeof (this.props.customerSelection) === 'number') {

            if (this.props.LoadData[0].Trailer === null || this.props.LoadData[0].Trailer == 'null') {
                window.alert('Please enter Trailer.')
            }
            else if (this.props.LoadData[0].Carrier === null || this.props.LoadData[0].Carrier == 'null') {
                window.alert('Please enter Carrier.')
            }
            else if (this.props.LoadData[0].BOL === null || this.props.LoadData[0].BOL == 'null') {
                window.alert('Please enter BOL.')
            }
            else if (this.props.LoadData[0].Seal === null || this.props.LoadData[0].Seal == 'Seal') {
                window.alert('Please enter Seal.')
            }
            else if (window.confirm('Depart Load?')) {
                this.props.loadPost(this.props.currentUserEmail, this.props.userSignInData.AuthToken, this.props.LoadData[0].LoadID, null, null, null, null, null, null, null, null, 2, null, null, null, null, this.props.warehouseSelection, this.props.customerSelection, null, null, null, null, null, null, null)
            }
        }
        else { window.alert('Please select only one Warehouse and one Customer to save data.') }
    }





    //File Upload
    onClickDownloadFileUpload(e, rowData, history) {
        try {
            this.props.downloadFileFromS3(this.props.currentUserEmail, this.props.userSignInData.AuthToken, rowData.S3FileName, rowData.FileName, appAPI.API.data.FileDownloadXDockS3)
        }
        catch (e) {
            window.alert("Error Downloading File.")
        }
    }

    onClickDeleteFileUpload(e, rowData, history) {
        if (window.confirm('Do you wish to delete this file?'))
            this.props.xDockLoadFileUploadPost(
                this.props.currentUserEmail,
                this.props.userSignInData.AuthToken,
                this.props.customerSelection,
                formatNumberForAPI(rowData.FileUploadID),
                null,
                null,
                null,
                null,
                1
            )
    }





    render() {

        const { sortColumn, sortType, sortColumnDrawer, sortTypeDrawer } = this.state;

        let pageHeader;
        let detailsDiv;
        let editLoadDrawerDiv;
        let houseButtonDiv;
        let fileUploadDiv;
        if (this.props.isLoadLoading) {
            houseButtonDiv = <div></div>
            editLoadDrawerDiv = <div></div>
            detailsDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.LoadData[0]) !== 'undefined' && this.props.LoadData != null) {

            var currentLoadStatus = this.props.LoadData[0].CurrentLoadStatusID

            var loadID = this.props.LoadData[0].LoadID

            var s3FilePrefix = 'Load_' + loadID


            fileUploadDiv = RUploaderGeneral(
                this.props.isMobile,                          // isMobile,
                this.props.history,                           // history,
                this.props.currentUserEmail,                  //currentUserEmail,
                this.props.userSignInData,                    //userSignInData
                this.props.customerSelection,                 //customerSelection
                this.props.XdockLoadFileUploadData,      //fileList,
                this.props.xDockLoadFileUploadPost,     //onUploadAction,
                null,                                         // loading,
                loadID,                                       // dbID,
                s3FilePrefix,     // s3FilePrefix,
                appAPI.API.data.FileDownloadXDockS3   //s3 bucket
            )

            houseButtonDiv = currentLoadStatus != 4 ? <Row>
                House List
                <Button onClick={(e) => { this.onClickAddToLoad(e) }} style={{ float: 'right' }}> Add to Load </Button>
            </Row> : <div></div>

            editLoadDrawerDiv = <EditLoadDrawer {...this.props} state={this.state}
                onChange={this.onChangeEditLoadDrawer}
                onClickSave={this.onClickSaveEditLoadDrawer}
                close={this.closeEditLoadDrawer}
            />

            pageHeader = 'Airfreight - Load Details'
            detailsDiv =
                <div>


                    <Row style={{ marginBottom: 10 }} >
                        <Col md={24} sm={24}>
                            {currentLoadStatus != 4 ? <ButtonToolbar style={{ float: 'right' }}>  {this.props.LoadData[0].CurrentLoadStatusID == 1 ? <Button onClick={(e) => { this.onClickDepartLoad(e) }}>Depart Load</Button> : ''}  <Button onClick={(e) => { this.openEditLoadDrawer(e) }} > Edit Load </Button> <Button onClick={(e) => { this.onClickExportLoad(e) }} > Manifest </Button><Button onClick={(e) => { this.onClickDeleteLoad(e) }} > Delete Load </Button></ButtonToolbar> : ''}
                        </Col>
                    </Row>
                    <Row >

                        <Col md={12} sm={12}>
                            <Panel bordered style={{ marginBottom: 10 }} >
                                Carrier: <strong>{this.props.LoadData[0].Carrier}</strong>
                                <br />
                                Trailer: <strong>{this.props.LoadData[0].Trailer}</strong>
                                <br />
                                BOL: <strong>{this.props.LoadData[0].BOL}</strong>
                                <br />
                                Seal: <strong>{this.props.LoadData[0].Seal}</strong>
                                <br />
                                Consignee: <strong>{this.props.LoadData[0].ConsigneeName}</strong>
                                <br />
                                Destination: <strong>{this.props.LoadData[0].DestinationName}</strong>
                                <br />
                                Load Status: <strong>{this.props.LoadData[0].LoadStatusName}</strong>
                                <br />


                            </Panel>
                        </Col>



                        <Col md={12} sm={12}>
                            <Panel bordered style={{ marginBottom: 10 }}  >
                                MC #: <strong>{this.props.LoadData[0].MC}</strong>
                                <br />
                                DOT #: <strong>{this.props.LoadData[0].DOT}</strong>
                                <br />
                                Driver: <strong>{this.props.LoadData[0].Driver}</strong>
                                <br />
                                Truck #: <strong>{this.props.LoadData[0].Truck}</strong>
                                <br />
                                Truck Plate: <strong>{this.props.LoadData[0].TruckPlate}</strong>
                                <br />
                                Trailer Plate: <strong>{this.props.LoadData[0].TrailerPlate}</strong>
                                <br />
                                Notes: <strong>{this.props.LoadData[0].Notes}</strong>
                                <br />

                                
                            </Panel>
                        </Col>


                    </Row>

                    <Row >
                        <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>
                                Total PCS: <strong>{this.props.LoadData[0].TotalPCS}</strong>
                                <br />
                                Total Pallets: <strong>{this.props.LoadData[0].TotalPallets}</strong>
                                <br />

                            </Panel>
                        </Col>
                        <Col md={12} sm={24}>
                            <Panel bordered style={{ marginBottom: 10 }}>

                                Pickup Date: <strong>{this.props.LoadData[0].PickupDate}</strong>
                                <br />

                                Delivery Date: <strong>{this.props.LoadData[0].DeliveryDate}</strong>
                                <br />


                            </Panel>
                        </Col>
                    </Row>
                </div>

        }
        else {
            detailsDiv = <div></div>;
            editLoadDrawerDiv = <div></div>;
            houseButtonDiv = <div></div>
        }





        let loadDiv;
        let rTable;
        if (this.props.isLoadHawbLoading) {
            rTable = <div></div>
            loadDiv =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.LoadHawbData) !== 'undefined' && this.props.LoadHawbData != null) {

            if (this.props.LoadHawbData.length === 0) {
                rTable = <div></div>
                loadDiv = <div></div>
            }
            else {

                // let rTable;
                rTable = RTable(
                    this.props.LoadHawbData,        // tableData,
                    LoadHawbDataElements,  // DataElements,
                    sortColumn,                 // sortColumn,
                    sortType,                   // sortType,
                    this.props.isLoadHawbLoading,         // loading,
                    this.handleSortColumn,      // handleSortColumn,
                    this.onClickDetails,        // onClickDetails,
                    false,                      // checkColEntry,
                    tableIdCol,                 // keyColumn,
                    null,                       // checkedKeys,
                    null,                       // handleCheckAll,
                    null,                       // handleCheck,
                    // false,                      // boolSelectButton,
                    false,
                    null,
                    // this.onClickReceive,     // onClickSelect,
                    'Receive',                       // selectButtonText,
                    this.props.isMobile,        // isMobile,
                    this.props.history,         // history,
                    null,                        // highlightRowKey

                    true,// editLink,
                    'Remove', // editLinkText,
                    this.onClickRemoveFromLoad// editLinkOnClick
                )

                loadDiv = <div>


                    {(this.props.isMobile == true) ? <div></div> :
                        <div>
                            <Row>
                                <ExcelFile element={<Button >Export Excel</Button>} filename={'HAWB_' + currentDateTime}>
                                    <ExcelSheet data={this.props.LoadHawbData} name="HAWB">

                                        {
                                            LoadHawbDataElements.map(
                                                ({ dBKey, displayName }, idx) => {
                                                    return (
                                                        <ExcelColumn label={displayName} value={dBKey} />
                                                    );
                                                }
                                            )
                                        }

                                    </ExcelSheet>
                                </ExcelFile>
                            </Row>
                        </div>
                    }

                </div>
            }
        }
        else {
            rTable = <div></div>
            loadDiv = <div></div>
        }



        let loadTimeline;
        if (this.props.isLoadHawbHistoryLoading) {
            loadTimeline =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.LoadHawbHistoryData[0]) !== 'undefined' && this.props.LoadHawbHistoryData != null) {
            loadTimeline =
                <div>
                    <Panel header="Load Events" bordered style={{ marginBottom: 10 }}>
                        <Timeline>
                            {this.props.LoadHawbHistoryData.map(item => (
                                <Timeline.Item>
                                    {item.LoadHawbDT}  &nbsp;
                                    {item.EmailAddr}
                                    {item.HouseRef != null ? <l><br /> House:  {item.HouseRef} </l> : ' '}
                                    {item.PCS != 0 ? <l><br /> PCS:  {item.PCS} </l> : ' '}
                                    {item.Pallets != 0 ? <l><br /> Pallets:  {item.Pallets} </l> : ' '}
                                    {item.Notes != null ? <l><br /> Notes:  {item.Notes} </l> : ' '}
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </Panel>
                </div>
        }


        let buildLoadDrawer;
        if (this.props.isHawbLoading) {
            buildLoadDrawer = <div align="center">
                <section className="panel">
                </section>
                <Loader
                    type="TailSpin"
                    color="#3396E5"
                    height="50"
                    width="50"
                />
            </div>
        }
        else if (typeof (this.state.BuildLoadDict) !== 'undefined' && this.state.BuildLoadDict != null && this.state.BuildLoadDict.length > 0 && this.state.showBuildLoadDrawer) {
            buildLoadDrawer = <AddToLoadDrawer {...this.props} state={this.state}
                onClickAdd={this.onClickAdd}
                onChange={this.onChangeBuildLoadDrawer}
                close={this.closeBuildLoadDrawer}
                handleSortColumn={this.handleSortColumnDrawer}
                sortColumn={this.state.sortColumnDrawer}
                sortType={this.state.sortTypeDrawer}
                onClickSearch={this.onClickSearch}
                onClickReset={this.onClickReset}
                tableIdCol={tableIdCol}
            />
        }





        let fileUploadsTable;
        if (this.props.isXdockLoadFileUploadLoading) {

            fileUploadsTable =
                <div align="center">
                    <section className="panel">
                    </section>
                    <Loader
                        type="TailSpin"
                        color="#3396E5"
                        height="50"
                        width="50"
                    />
                </div>
        }
        else if (typeof (this.props.XdockLoadFileUploadData) !== 'undefined' && this.props.XdockLoadFileUploadData != null && this.props.XdockLoadFileUploadData.length > 0) {
            var milestoneTableComponent = RTableGeneral(
                "FileUploadID",     //   keyColumn,
                this.props.XdockLoadFileUploadData,     //   tableData,
                XdockLoadFileUploadsDataElements,       //   DataElements,
                this.props.isXdockLoadFileUploadLoading,        //   loading,

                this.props.isMobile,        //   isMobile,
                this.props.history,     //   history,

                sortColumn,     //   sortColumn,
                sortType,       //   sortType,
                this.handleSortColumn,      //   handleSortColumn,

                null, // checkColEntry,
                null,       //   checkedKeys,
                null,       //   handleCheckAll,
                null,       //   handleCheck,

                true,      //   onClickEditMilestone,
                this.onClickDownloadFileUpload,       //   onClickSelect,
                'Download',       //   selectButtonText,
                null,       //   selectHighlightRowKey,

                true,       //   link1bool,
                'Delete',     //   link1text,
                this.onClickDeleteFileUpload,     //   link1onClick,

                false,       //   link2bool,
                null,       //   link2text,
                null  //   link2onClick
            )

            fileUploadsTable = <Panel
                bordered style={{ marginBottom: 10 }}
                header='File Uploads'
            >
                {milestoneTableComponent}
            </Panel>


        }
        else {
            fileUploadsTable = <div></div>
        }




        return (
            <div>
                <h3>
                    {pageHeader}
                </h3>

                <PanelGroup>

                    {detailsDiv}

                </PanelGroup>



                <Panel header={houseButtonDiv} bordered style={{ marginBottom: 10 }}>
                    {loadDiv}
                    {rTable}
                </Panel>

                {loadTimeline}


                {buildLoadDrawer}
                {editLoadDrawerDiv}
                <AddNewModal {...this.props} state={this.state} addNewModalShown={this.state.addNewModalShown} onChangeExisting={this.onChangeExistingBuildLoadDrawer} handleOpen={this.handleOpenAddNewModal} handleClose={this.handleCloseAddNewModal} handleSave={this.handleSaveAddNewModal} />
                <LoadExportModal {...this.props} state={this.state}
                    isModalShown={this.state.isModalShown}
                    handleClose={this.handleCloseExportModal}
                />





                {fileUploadsTable}
                {fileUploadDiv}



            </div>
        );
    }
}

export default withRouter(LoadDetails);

